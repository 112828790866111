import React from 'react'

const MenuIcon = ({menufill = "#1b1b1b"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 242" fill={menufill}>
      <title>menu-icon</title>
      <path d="M71.3,32.78h-.41l-7,13.93L52.09,68.21a7.63,7.63,0,0,1-13.39,0L27.18,46.85l-7.24-15h-.41V87.71a7.63,7.63,0,0,1-7.62,7.63H7.62A7.63,7.63,0,0,1,0,87.71V7.62A7.62,7.62,0,0,1,7.62,0h9.75a7.64,7.64,0,0,1,6.76,4.09L45.35,44.66h.27L66.57,4.12A7.62,7.62,0,0,1,73.34,0h9.87a7.62,7.62,0,0,1,7.62,7.62V87.71a7.63,7.63,0,0,1-7.62,7.63H78.92a7.63,7.63,0,0,1-7.62-7.63Z" />
      <path d="M135.12,87.71V7.62A7.62,7.62,0,0,1,142.75,0h49.63A7.62,7.62,0,0,1,200,7.62v3.2a7.62,7.62,0,0,1-7.62,7.62H163.51a7.63,7.63,0,0,0-7.63,7.62v4.29A7.63,7.63,0,0,0,163.51,38h22.58a7.63,7.63,0,0,1,7.63,7.63v3a7.63,7.63,0,0,1-7.63,7.62H163.51a7.64,7.64,0,0,0-7.63,7.63v5.37a7.63,7.63,0,0,0,7.63,7.63h28.87A7.62,7.62,0,0,1,200,84.52v3.19a7.63,7.63,0,0,1-7.62,7.63H142.75A7.63,7.63,0,0,1,135.12,87.71Z" />
      <path d="M29.79,195.38l-.28-.53-9-18.85h-.41v56.57a7.63,7.63,0,0,1-7.63,7.62H8.17a7.62,7.62,0,0,1-7.62-7.62V152.48a7.63,7.63,0,0,1,7.62-7.63H18.82a7.62,7.62,0,0,1,6.6,3.81l23.74,41c.1.17.19.35.28.53l9,18.85h.41V152.48a7.63,7.63,0,0,1,7.62-7.63h4.29a7.63,7.63,0,0,1,7.62,7.63v80.09a7.62,7.62,0,0,1-7.62,7.62H60.12a7.61,7.61,0,0,1-6.59-3.8Z" />
      <path d="M137.6,144.85a7.63,7.63,0,0,1,7.62,7.63v50.83c0,13.39,4.92,20.08,17.21,20.08s17.21-6.69,17.21-20.08V152.48a7.63,7.63,0,0,1,7.62-7.63h5a7.64,7.64,0,0,1,7.63,7.63v48.65c0,28.13-10.11,40.7-37.43,40.7S125,229.26,125,201.13V152.48a7.63,7.63,0,0,1,7.62-7.63Z" />
    </svg>
  )
}

export default MenuIcon
