import React from 'react'

const WufooForm = require('react-wufoo-embed');

const Form = () => {
  
  return (
    <div>
      <WufooForm userName="hoodiehut1" formHash="z1qm9qyi0efacmf" header="hide" />
    </div>
  )
}

export default Form
